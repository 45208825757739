import { useFormikContext } from "formik";
import { useI18n } from "../../src/ui/hooks/useI18n";
import { Asserts } from "yup";
import { YupHealthRecordSchema } from "../../src/ui/pages/health_record/HealthRecordForm";
import { forwardRef, useEffect } from "react";
import { useCountryConfig } from "../../src/ui/hooks/useCountryConfig";
import { StyledInput } from "../../src/ui/components/mui/StyledInput";
import ReactInputMask from "react-input-mask-format";
import { SecurityNumberInputProps } from "../../src/appConfig";

export function SecurityNumberInput({ isReadonly, translateErrors }: SecurityNumberInputProps) {
  const { t } = useI18n();
  const { values, getFieldProps, errors, touched, setFieldValue } = useFormikContext<Asserts<YupHealthRecordSchema>>();
  const { securityNumber } = useCountryConfig();

  useEffect(() => {
    const cleanedValue = values?.externalId?.trim().replaceAll(" ", "").replaceAll("_", "");
    const isValid = cleanedValue && securityNumber.validator(cleanedValue);

    if (isValid) {
      const genderFromExternalId = retrieveGenderFromExternalId(cleanedValue.slice(9, 10));
      const birthDateFromExternalId = retrieveBirthDateFromExternalId(
        cleanedValue.slice(0, 2),
        cleanedValue.slice(2, 4),
        cleanedValue.slice(4, 6),
      );
      if (genderFromExternalId !== undefined && !values.gender) {
        setFieldValue("gender", genderFromExternalId);
      }
      if (!values.birthDate) {
        setFieldValue("birthDate", birthDateFromExternalId);
      }
    }
  }, [
    errors?.externalId,
    values?.externalId,
    setFieldValue,
    values.birthDate,
    values.gender,
    securityNumber
  ]);

  return (
    <>
      <StyledInput
        {...getFieldProps("externalId")}
        fullWidth
        label={t("common.user_infos.social_security_number")}
        placeholder="ex : 010130 9999 7"
        inputComponent={!isReadonly ? (SocialSecurityMaskInput as any) : undefined}
        testId="externalId"
        readOnly={isReadonly}
        error={!!errors.externalId}
        errorMessage={errors.externalId}
        touched={touched.externalId}
        translateErrors={translateErrors}
        value={values.externalId}
      />
    </>
  );
}

function retrieveGenderFromExternalId(genderDigit: string): string | undefined {
  if (genderDigit === undefined ) { return undefined; } 

  if (parseInt(genderDigit) % 2 == 0) {
    return "M";
  } else {
    return "F";
  }
}

function retrieveBirthDateFromExternalId(yearDigit: string, monthDigit: string, dayDigit: string): Date {
  const yearFromExternalId = new Date().getFullYear().toString().slice(0, 2) + yearDigit;
  const birthDateFromExternalId = new Date(`${yearFromExternalId}-${monthDigit}-${dayDigit}`);

  if (birthDateFromExternalId > new Date()) {
    birthDateFromExternalId.setFullYear(parseInt(yearFromExternalId) - 100);
  }

  return birthDateFromExternalId;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: string;
}
const SocialSecurityMaskInput = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  return (
    <ReactInputMask
      {...props}
      mask="999999 9999 9"
      onChange={props.onChange}
      value={props.value}
      ref={ref as any}
    />
  );
});